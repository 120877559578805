var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fil-ariane" }, [
    _vm.navigation && _vm.navigation.length
      ? _c(
          "ul",
          _vm._l(_vm.navigation, function (nav, i) {
            return _c(
              "li",
              { key: `nav${i}` },
              [
                nav.link !== ""
                  ? _c("router-link", { attrs: { to: nav.link } }, [
                      _vm._v(_vm._s(nav.name)),
                    ])
                  : _vm._e(),
                nav.link === ""
                  ? _c("span", [_vm._v(_vm._s(nav.name))])
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }